<template>
    <div class="container mt-3">


        <h2>
            <i class="bi bi-person-lines-fill"></i>
            {{ $t('titles.userList') }}
        </h2>

        <div class="row my-3">

            <div class="col">

                <button
                    type="button"
                    tag="button"
                    class="btn btn-outline-primary btn-sm mx-3"
                    @click.prevent="createResource()"
                  >
                    <i class="bi bi-pencil-square"></i>
                    {{ $t('form.actions.newUser') }}
                </button>
            </div>
        </div>

        <div class="row my-3">

            <div class="col-9 ms-3">

                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="state.form.search"
                        @keyup.enter="searchUsers()"
                    >
                    <button
                        class="btn btn-outline-secondary"
                        type="button"
                        @click.prevent="searchUsers()"
                    >
                        <i class="bi bi-search"></i>
                        {{ $t('form.actions.search') }}
                    </button>


                </div>
            </div>

            <div class="col-2 me-3">

                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click.prevent="getAllUsers()"
                >
                    <i class="bi bi-arrow-repeat"></i>
                </button>

            </div>
        </div>

        <div class="row">
            <div class="col align-self-start">
                <div class="ms-3 my-3">

                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="filters">{{ $t('form.actions.filtersOn') }} :</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(0)">
                        <label class="form-check-label" for="news">News</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(1)">
                        <label class="form-check-label" for="users">Users</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(2)">
                        <label class="form-check-label" for="masters">Masters</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(3)">
                        <label class="form-check-label" for="admins">Admins</label>
                    </div>

                </div>
            </div>

            <div class="col align-self-center">
                <ul class="justify-content-end nav">

                    <NavItem
                        :quantity="10"
                        :selected="useUsers.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                    <span class="align-self-center mx-1"> | </span>

                    <NavItem
                        :quantity="20"
                        :selected="useUsers.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                    <span class="align-self-center mx-1"> | </span>

                    <NavItem
                        :quantity="useUsers.state.pagination.meta.total"
                        :selected="useUsers.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                </ul>
            </div>
        </div>

        <div
            v-show="useUsers.state.form.loading"
            class="spinner"
        >
            Loading users...
        </div>

        <div class="d-flex justify-content-center">
            <div v-show="useUsers.state.form.loading" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- Display server error messages, from server validation -->
        <!-- Diplay alert message received from backend -->
        <MyModal
            v-bind:idProps="state.modal.alert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>

                <AltertMessage
                    v-bind:data="useUsers.state.form.response"
                ></AltertMessage>

            </template>

        </MyModal>


        <div
            v-if="!useUsers.state.form.loading"
        >

            <div class="table-responsive">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.user.id')"
                                    :sortArgs="useUsers.tableSort"
                                    :headerName="'id'"
                                    @sort-event="useUsers.sortUsers('id', 'number')"
                                />
                            </th>
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.user.name')"
                                    :sortArgs="useUsers.tableSort"
                                    :headerName="'name'"
                                    @sort-event="useUsers.sortUsers('name', 'string')"
                                />
                            </th>
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.user.email')"
                                    :sortArgs="useUsers.tableSort"
                                    :headerName="'email'"
                                    @sort-event="useUsers.sortUsers('email', 'string')"
                                />
                            </th>
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.user.role')"
                                    :sortArgs="useUsers.tableSort"
                                    :headerName="'role'"
                                    @sort-event="useUsers.sortUsers('role', 'number')"
                                />
                            </th>

                            <th scope="col">{{ $t('models.user.created_at') }}</th>
                            <th scope="col">{{ $t('models.user.updated_at') }}</th>
                            <th scope="col">{{ $t('models.user.edit') }}</th>
                            <th scope="col">{{ $t('models.user.validate') }}</th>
                            <th scope="col">{{ $t('models.user.delete') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="user in useUsers.state.selectedUsers"
                            v-bind:key="user"
                        >
                            <td>{{ user.id }}</td>
                            <td>{{ user.name }}</td>
                            <td>
                                <a v-bind:href="'mailto:' + user.email">
                                    {{ user.email }}
                                </a>
                            </td>
                            <td>{{ user.role }}</td>
                            <td>{{ formatedDate(user.created_at) }}</td>
                            <td>{{ formatedDate(user.updated_at) }}</td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    data-bs-toggle="modal"
                                    @click.prevent="editResource(user)"
                                >
                                    <i class="bi bi-pencil-fill"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    v-if="user.role == 0"
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="validateResource(user)"
                                >
                                    <i class="bi bi-check-square"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="deleteResource(user)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row my-3 py-3">
            <nav aria-label="Page navigation example" class="my-3">
                <ul class="pagination justify-content-center mb-3">
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            aria-label="Previous"
                            @click.prevent="getPreviousUsersPagination()"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    <li
                        v-for="page in useUsers.state.pagination.meta.lastPage"
                        v-bind:key="page"
                        class="page-item"
                        v-bind:class="page === useUsers.state.pagination.meta.curentPage ? 'active' : ''"
                        @click.prevent="getPageUsersPagination(page)"
                    >
                        <a class="page-link" href="#">{{ page }}</a>
                    </li>

                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            aria-label="Next"
                            @click.prevent="getNextUsersPagination()"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>


        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>

                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="useUsers.useErrors.state.exception"
                    v-bind:exceptionMessage="useUsers.useErrors.state.exceptionMessage"
                ></ExceptionMessage>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="name"
                        v-bind:class="formUtilsComp.isFieldEmpty(useUsers.state.form.body.name) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="name"
                        v-model="useUsers.state.form.body.name"
                    >
                    <label for="name">Username</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useUsers.state.form.body.name)" class="text-info text-sm">Please fill up with your name</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useUsers.useErrors.state.errors.name"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="email"
                        class="form-control rounded-4"
                        id="email"
                        v-bind:class="formUtilsComp.isEmailValid(useUsers.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                        @keyup.enter="saveResource()"
                        placeholder="name@example.com"
                        v-model="useUsers.state.form.body.email"
                    >
                    <label for="email">Email address</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="!formUtilsComp.isEmailValid(useUsers.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useUsers.useErrors.state.errors.email"></ValidationErrors>
                </div>

                <div v-if="state.modal.action !== 'edit'" class="form-floating mb-3">
                    <input
                        :type="useUsers.state.form.show_password ? 'text' : 'password'"
                        class="form-control rounded-4"
                        id="password"
                        v-bind:class="formUtilsComp.isPasswordEmpty(useUsers.state.form.body.password) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="Password"
                        v-model="useUsers.state.form.body.password"
                    >
                    <label for="password">Password</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isPasswordEmpty(useUsers.state.form.body.password)" class="text-info text-sm">Please enter your password</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useUsers.useErrors.state.errors.password"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="role"
                        v-model="useUsers.state.form.body.role"
                        @keyup.enter="saveResource()"
                    >
                        <option value="">Select role...</option>
                        <option v-for="option in useUsers.state.form.options" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="role">Role</label>
                </div>

                <div v-if="state.modal.action !== 'edit'" class="form-floating mb-3">
                    <div class="checkbox mb-1">
                        <label>
                            <input
                                v-model="useUsers.state.form.show_password"
                                @keyup.enter="saveResource()"
                                type="checkbox"
                                value="show-password"
                            > Show password
                        </label>
                    </div>
                </div>


                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >
                    {{ $t('form.actions.save') }}
                </button>


            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal>

    </div>
</template>

<script>
// import Vue.js framework functions
import { reactive } from 'vue'
import { onMounted } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'
import TableHeaderItem from '@/components/table/TableHeaderItem.vue'
import NavItem from '@/components/navigation/NavItem.vue'

// import composables files
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useModalsComp from '@/composables/useModalsComp.js'
import useUsersComp from '@/composables/useUsersComp.js'

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

import { useI18n } from 'vue-i18n'

//import moment from 'moment'


export default {

    name: 'users',

    components : {
        MyModal,
        TableHeaderItem,
        NavItem,
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },


    setup() {
        const objUtils = useObjectsUtils()
        const dateTime = useDateTimeUtilsComp()
        const useUsers = useUsersComp()
        const formUtilsComp = useFormUtilsComp()
        const useModals = useModalsComp()

        const { t, locale } = useI18n({ useScope: 'global' })

        //const momentFunctions = moment
        //const internalInstance = reactive( getCurrentInstance() )

        const state = reactive({
            modal: {
                //id: 'userModal',
                edit: 'editUserModal',
                show: 'showUserModal',
                alert: 'alertModal',
                header: '',
                action: '',
                component: null,
                componentEdit: null,
                componentAlert: null
            },
            form: {
                search: '',
            },
        });

        /**
         * Function called when component is mounted
         * @param  {[type]} state               [description]
         * @return {[type]}       [description]
         */
        onMounted(() => {
            //useUsers.getUsers()
            //useUsers.state.modal.component = new Modal(document.getElementById('myModalId'))
            //state.modal.component = new Modal(document.getElementById(state.modal.id))
            //state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            //state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))

            useModals.state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            //useModals.state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
            useModals.state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))

            useUsers.state.pagination.meta.requestQtyPerPage = 5
            useUsers.state.pagination.meta.requestedPage = 1

            //getAllUsers()
            getUsersPagination()
        });


        /**
         * Function called from seach input field. Take the entered name
         * and send a request to the backend to find the given customer
         * @return {[type]} [description]
         */
        function searchUsers() {

            useUsers.searchResources(state.form.search)
            .then(() => {
                useUsers.sortUsers('name', 'string')
                state.form.search = ''
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useUsers.useErrors.useSetErrors(error.response.data)
            })
        }

        /**
         * Set the amount of resources to display
         * @param {Number} nb  [Amount of resources to diaplay]
         */
        function setNbPerPageForPagination(nb) {
            useUsers.state.pagination.meta.requestQtyPerPage = nb
            useUsers.state.pagination.meta.requestedPage = 1
            getUsersPagination()
        }

        /**
         * Get resources corresponding to the selected page number
         * @param  {Number} page    [Selected page number]
         * @return {[type]}      [description]
         */
        function getPageUsersPagination(page) {
            useUsers.state.pagination.meta.requestedPage = page
            getUsersPagination()
        }

        /**
         * Get resources corresponding to the next page number
         * @return {Number} [Nest page number]
         */
        function getNextUsersPagination() {
            if ( useUsers.state.pagination.links.next !== null ) {
                useUsers.state.pagination.meta.requestedPage = useUsers.state.pagination.meta.curentPage + 1
                getUsersPagination()
            }
        }

        /**
         * Get resources corresponding to the previous page number
         * @return {Number} [Previous page number]
         */
        function getPreviousUsersPagination() {
            if ( useUsers.state.pagination.links.prev !== null ) {
                useUsers.state.pagination.meta.requestedPage = useUsers.state.pagination.meta.curentPage - 1
                getUsersPagination()
            }
        }

        /**
         * Called from the get...UsersPagination functions.
         * Gets the resources from backend corresponding to selected page number
         * @return {Array} users    [Array of resources returned from backend]
         */
        function getUsersPagination() {

            useUsers.getResourcesPaginate()
            .then(() => {
                useUsers.sortUsers('name', 'string')
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useUsers.useErrors.useSetErrors(error.response.data)
            })
        }


        /**
         * Gets all resources from backend, independently of pagination
         * @return {[type]} [description]
         */
        function getAllUsers() {
            useUsers.getUsers()
            .then(() => {
                useUsers.sortUsers('name', 'string')
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useUsers.useErrors.useSetErrors(error.response.data)
            })
        }


        /**
         * Return formated date to display in local form (header of table display)
         * @param  {Object} date    Date to format
         * @return {Object}     Formated date
         */
        function formatedDate(date) {
            //return dateTime.formatedDate(date, state.lang, "LLLL")
            //return dateTime.formatedDate(date, locale.value, "LLLL")
            return dateTime.formatedDate(date, locale.value, "dddd D.M.YYYY")
            //return useDateTimeUtils.formatedDate(new Date(date), locale.value, "dddd D.M.YYYY")
        }

        /**
         * Set modal attributes and open modal form to create a resource
         * @return {void} [void]
         */
        function createResource() {

            // Define modal attributes
            //state.modal.header = t('form.cards.users.headers.create')
            //state.modal.action = 'create'
            useModals.setCreateModalAttributes( 'users' )

            // Open Modal component
            //state.modal.componentEdit.toggle()

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useUsers.useErrors.useResetErrors()

            // Flush user's attributes
            useUsers.flushAttributes()
            useUsers.initAttributes()
        }

        // Clone resource to update before insertion in form
        function editResource(resource) {

            // Define modal attributes
            //state.modal.header = t('form.cards.users.headers.edit')
            //state.modal.action = 'edit'
            useModals.setEditModalAttributes( 'users' )

            // Open Modal component
            //state.modal.componentEdit.toggle()

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useUsers.useErrors.useResetErrors()

            // Clone user's attributes
            useUsers.state.form.body = objUtils.cloneObject(resource)
        }

        /**
         * Toogle the display of modal form is close button clicked
         * @return {void} [void]
         */
        function closeShowResource() {
            // Close Modal component
            useModals.state.modal.componentShow.toggle()
        }

        function validateResource(resource) {

            // Clone user's attributes
            useUsers.state.form.body = objUtils.cloneObject(resource)

            // Set user's role to 1
            useUsers.state.form.body.role = 1

            // Save validated resource
            useUsers.updateResource()
            .then(() => {
                useUsers.state.form.response.alert = 'alert-success'
            })
            .catch(() => {
                useUsers.state.form.response.alert = 'alert-danger'
            })
            .then(() => {
                // Open Modal component
                state.modal.componentAlert.toggle()
                // Reset message after a few milliseconds
                setTimeout(() => {
                    state.modal.componentAlert.toggle()
                }, 2000)
            })

        }

        // Save new created or updated resource
        function saveResource() {

            if ( useUsers.state.form.body.id == null ) {

                useUsers.storeResource()
                .then(() => {
                    // Close Modal component
                    //state.modal.componentEdit.toggle()


                    useUsers.state.form.response.alert = 'alert-success'
                    // Close Modal component
                    useModals.state.modal.componentEdit.toggle()

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useUsers.state.form.response.alert = 'alert-danger'
                    //formUtilsComp.useSetErrors(error.response.data)
                    useUsers.useErrors.useSetErrors(error.response.data)
                })
            } else {
                useUsers.updateResource()
                .then(() => {
                    // Close Modal component
                    //state.modal.componentEdit.toggle()

                    useUsers.state.form.response.alert = 'alert-success'
                    // Close Modal component
                    useModals.state.modal.componentEdit.toggle()

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useUsers.state.form.response.alert = 'alert-danger'
                    //formUtilsComp.useSetErrors(error.response.data)
                    useUsers.useErrors.useSetErrors(error.response.data)
                })
            }
        }

        function deleteResource(resource) {

            if ( confirm(t('form.infos.delete.confirmation') + ` '${resource.name}' - email : '${resource.email}'`) ) {
                useUsers.deleteResource(resource)
                .then(() =>{
                    //useModals.setDeleteModalAttributes( 'customers' )
                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useUsers.state.form.response.alert = 'alert-danger'
                    //formUtilsComp.useSetErrors(error.response.data)
                    useUsers.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            }
        }

        return {
            state,
            useUsers,
            useModals,
            formUtilsComp,
            searchUsers,
            getAllUsers,
            createResource,
            editResource,
            saveResource,
            deleteResource,
            validateResource,
            closeShowResource,
            formatedDate,
            getNextUsersPagination,
            getPreviousUsersPagination,
            setNbPerPageForPagination,
            getPageUsersPagination,
        }
    }
}
</script>

<style>
    @import '../../assets/css/logo.css';
</style>
